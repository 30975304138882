import React, { Suspense } from 'react';
// @ts-expect-error not converted yet
import * as serviceWorker from './registerServiceWorker';
import { SpinnerComponent } from './components/Reusable';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';

const container = document.getElementById('app') ?? new HTMLElement;
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <Suspense fallback={<SpinnerComponent open={true} />}>
            <App />
        </Suspense>
    </BrowserRouter>
);
serviceWorker.unregister();