import React, { useState, useEffect } from 'react';
import { IconButton, ImageList, ImageListItem, ImageListItemBar, styled } from '@mui/material';
import { ModalComponent } from '../Reusable';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import ImageViewerDialog from './ImageViewerDialog';
import ImageNameEditingInput from './ImageNameEditingInput';
import { API } from '../../helper/ApiHelper';
import { Delete, AddCircleOutline, CheckCircle, CheckCircleOutline, Cancel, NavigateBefore, NavigateNext } from '@mui/icons-material';
import './ImageViewer.scss';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import { MapInteractionCSS } from 'react-map-interaction';
import { IdParam, ModalType, ModalTypeInit } from '../Types/Types';

export interface ProjectImage {
    image: ImageData,
    thumbnail: ImageData
}

export interface ImageData {
    name: string,
    dateModified: Date,
    url: string,
    height : number,
    width : number,
    groupId : string,
    isThumbnail : boolean,
    tabIndex : number
}

interface IProps {
    groupId: string,
    disabled?: boolean
}

export default function ImageViewer(props: IProps) {
    const blobUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob";
    const { id } = useParams<IdParam>();
    const { t } = useTranslation();
    const [images, setImages] = useState<ProjectImage[]>([]);
    const [selectedImage, setSelectedImage] = useState<ProjectImage | null>(null);
    const [imagesForDeletion, setImagesForDeletion] = useState<ProjectImage[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [inEditMode, setInEditMode] = useState<number | null>(null);
    const [modalModel, setModalModel] = useState<ModalType>(ModalTypeInit);

    useEffect(() => {
        fetchData();
    }, [props.groupId]);

    const fetchData = async () => {
        const res = await API.get(`${blobUrl}/project/${id}/images/${props.groupId}`);
        setImages(res);
    };

    const clickDelete = () => {
        const imgToDelete = images.filter((x) => imagesForDeletion.includes(x)).map(y => y.image.name);
        if (imgToDelete == undefined || imgToDelete.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal(imgToDelete);
    }

    const clickDeleteOpenModal = (imgToDelete: string[]) => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('ImageDeleteConfirmation');
        modalModel.clickOk = () => clickDeleteModalOk(imgToDelete);
        setModalModel({ ...modalModel });
    }

    const clickDeleteModalOk = async (imgToDelete: string[]) => {
        const data = {
            groupId: props.groupId,
            FileNameList: imgToDelete
        };

        await API.post(`${blobUrl}/project/${id}/images/delete`, data);
        OpenGlobalSnackbar("success", t('ImagesDeletedSuccessfully'));
        const filtered = images.filter((x) => !imagesForDeletion.includes(x));
        setImages(filtered);
        setImagesForDeletion([]);
    }

    const addImageClick = () => {
        if (images.length > 9) OpenGlobalSnackbar("danger", t('MaximumNumberOfFilesError'));
        else setShowDialog(true);
    }

    const updateImageSelection = (index: ProjectImage) => {
        let checkedArray;
        if (imagesForDeletion.includes(index))
            checkedArray = imagesForDeletion.filter(x => x != index);
        else
            checkedArray = [...imagesForDeletion, index];
        setImagesForDeletion(checkedArray);
    }

    const imageSelectPrevious = () => {
        const currentIndex = images.findIndex(x => x.image.name == selectedImage?.image.name);
        if (currentIndex > 0)
            setSelectedImage(images[currentIndex - 1]);
    }

    const imageSelectNext = () => {
        const currentIndex = images.findIndex(x => x.image.name == selectedImage?.image.name);
        if (currentIndex < images.length - 1)
            setSelectedImage(images[currentIndex + 1]);
    }

    const StyledDiv = styled('div')({
        border: "1px solid #ddd"
    });
    const StyledTitleBar = styled(ImageListItemBar)({
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.24) 0%, ' + 'rgba(0,0,0,0.12) 70%, rgba(0,0,0,0) 100%)'
    });
    const StyledImageListImg = styled('img')({
        height: 164,
        width: "100%",
        objectFit: "contain",
        top: "4px",
        left: "1px",
        transform: "unset",
        position: "unset",
        cursor: "pointer"
    });
    const StyledImageListFile = styled('div')({
        height: "164px",
        lineHeight: "164px",
        textAlign: "center",
        cursor: "pointer",
        '&::before': {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            clipPath: "polygon(0 0, 60% 0, 100% 40%, 100% 100%, 0 100%)",
            content: '""',
            position: "absolute",
            left: "25%",
            top: "18%",
            width: "50%",
            height: "40%",
        }
    });
    const StyledFullImageOverlay = styled('div')({
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        position: "fixed",
        top: "0px",
        left: "0px",
        zIndex: 9,
        display: selectedImage != null ? "flex" : "none",
    });
    const StyledCloseButton = styled(IconButton)({
        color: "#0091DC",
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 10,
        backgroundColor: "black"
    });
    const StyledNavigateButtonPrevious = styled(IconButton)({
        color: "#0091DC",
        position: "absolute",
        top: "50%",
        left: "10px",
        zIndex: 10,
        backgroundColor: "black"
    });
    const StyledNavigateButtonNext = styled(IconButton)({
        color: "#0091DC",
        position: "absolute",
        top: "50%",
        right: "10px",
        zIndex: 10,
        backgroundColor: "black"
    });
    return (
        <div className="ImageViewer">
            <ModalComponent model={modalModel} />
            <StyledFullImageOverlay>
                <StyledCloseButton
                    onClick={() => setSelectedImage(null)}
                    size="large"
                >
                    <Cancel />
                </StyledCloseButton>
                <StyledNavigateButtonPrevious
                    onClick={imageSelectPrevious}
                    size="large"
                >
                    <NavigateBefore />
                </StyledNavigateButtonPrevious>
                <StyledNavigateButtonNext
                    onClick={imageSelectNext}
                    size="large"
                >
                    <NavigateNext />
                </StyledNavigateButtonNext>
                {selectedImage != null &&
                    <MapInteractionCSS
                        defaultValue={{
                            scale: 1,
                            translation: {
                                x: window.innerWidth / 2 - selectedImage.image.width / 2,
                                y: window.innerHeight / 2 - selectedImage.image.height / 2
                            }
                        }}
                    >
                        <img
                            src={selectedImage.image.url}
                        />
                    </MapInteractionCSS>
                }
            </StyledFullImageOverlay>
            <ImageViewerDialog
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                groupId={props.groupId}
                images={images}
                setImages={setImages}
            />
            <div className="row">
                {!props.disabled &&
                    <div className="col-xs-12 align-content-right">
                        <IconButton onClick={clickDelete} id="btn_delete-button" size="large">
                            <Delete />
                        </IconButton>
                        <IconButton onClick={addImageClick} id="btn_add-button" size="large">
                            <AddCircleOutline />
                        </IconButton>
                    </div>
                }
            </div>
            {images.length == 0 ?
                <label>{t('NoFilesFound')}</label>
                :
                <StyledDiv>
                    <ImageList cols={5} rowHeight={216}>
                        {images.map((item, index) => (
                            <ImageListItem key={"image-gallery" + index}>
                                <StyledTitleBar
                                    title={item.image.name}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            onClick={() => updateImageSelection(item)}
                                            style={{ backgroundColor: 'transparent', color: imagesForDeletion.includes(item) ? '#0091DC' : 'grey' }}
                                            size="large"
                                        >
                                            {imagesForDeletion.includes(item)
                                                ?
                                                <CheckCircle />
                                                :
                                                <CheckCircleOutline />
                                            }
                                        </IconButton>
                                    }
                                    actionPosition="left"
                                />
                                {item.thumbnail != undefined ?
                                    <StyledImageListImg
                                        onClick={() => setSelectedImage(images[index])}
                                        src={item.thumbnail.url}
                                        loading="lazy"
                                    />
                                    :
                                    <StyledImageListFile onClick={() => window.open(item.image.url, "_blank")}>
                                        {item.image.url.slice(item.image.url.slice(0, item.image.url.indexOf("?")).lastIndexOf("."), item.image.url.indexOf("?"))}
                                    </StyledImageListFile>
                                }
                                <ImageNameEditingInput
                                    setInEditMode={setInEditMode}
                                    inEditMode={inEditMode}
                                    groupId={props.groupId}
                                    images={images}
                                    setImages={setImages}
                                    index={index}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </StyledDiv>
            }
        </div>
    );
}