import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { CountryParam, LanguageParam, ModalType } from '../Types/Types';
import { ModalComponent } from '../Reusable';
import { useTranslation } from 'react-i18next';

export function ThankYou() {
    const history = useHistory();
    const { t } = useTranslation();
    const { country, lng } = useParams<CountryParam & LanguageParam>();
    const [modalModel] = useState<ModalType>({
        open: true,
        message: t('ThankYouPageText'),
        okText: t('HomePageFestoButton'),
        headline: t('ThankYouPageHeadline'),
        clickClose: async () => history.push(`/${country}/${lng}/`),
        clickOk: async () => { window.location.href = "https://www.festo.com/de/en/e/support/energy-saving-services-id_21636" },
    });

    return (
        <div>
            <ModalComponent model={modalModel} />
        </div>
    );
}
