import React from 'react';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ModalType } from '../../Types/Types';
import { Close } from '@mui/icons-material';
import ButtonComponent from '../Button/Button';

// const StyledDialog = styled(Dialog)({
//     '& p': {
//         marginRight: '3rem'
//     }
// });

interface IProps {
    model: ModalType
}

export default function ModalComponent(props: IProps) {
    const [open, setOpen] = React.useState(false);
    const { model } = props;

    React.useEffect(() => {
        setOpen(model.open)
    }, [model.open]);

    const cancel = () => { setOpen(false); model.clickCancel?.(); }
    const close = () => { setOpen(false); model.clickClose?.(); }
    const ok = () => { setOpen(false); model.clickOk?.(); }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} closeAfterTransition aria-labelledby="transition-modal-title">
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <h2>{model?.headline}</h2>
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" size="small" onClick={close} id="btn_Modal-Close">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Grid item>
                        <p id="transition-modal-description">{model.message}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        {model?.cancelText !== undefined && <ButtonComponent value={model?.cancelText} color="primary" id="btn_Cancel" onChange={cancel}></ButtonComponent>}
                    </Grid>
                    <Grid item>
                        {model?.okText !== undefined && <ButtonComponent value={model?.okText} color="primary" id="btn_Yes" onChange={ok}></ButtonComponent>}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}