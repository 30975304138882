import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { usePdfStyles } from './PdfStyles';
import * as DateHelper from '../../../../helper/DateHelper';
import { SplitText } from '../../../../helper/PdfTested';
import { FlexTable } from '../../../PdfReport/PdfContent/PdfStyles';

export default function LeakageIdentificationTable(props) {
    const { item } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    return (
        <View style={PdfStyles.table}>
            <View style={{ width: "100%" }}>
                <View style={PdfStyles.tableRowHeader}>
                    <View style={PdfStyles.tableHeadRow}>
                        <View style={[PdfStyles.tableRowHeader1, { borderRight: "1px solid black" }]}>
                            <Text style={PdfStyles.tableHead}>{t('LeakageIdentification')}</Text>
                        </View>
                        <View style={[PdfStyles.tableRowHeader2, { borderRight: "1px solid black" }]}>
                            <Text style={PdfStyles.tableHead}>{t('Location')}</Text>
                        </View>
                        <View style={PdfStyles.tableRowHeader3}>
                            <Text style={PdfStyles.tableHead}>{t('RepairProcess')}</Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableHeadRow}>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('MeasurementId')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('EntryDate')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('DetectedBy')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('Building')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('Department')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight]}>
                            <Text style={PdfStyles.tableHead}>{t('Machine')}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, PdfStyles.tableCellHeight, { borderRight: "none" }]}>
                            <Text style={PdfStyles.tableHead}>{t('Repaired?')}</Text>
                        </View>
                    </View>
                    <View style={PdfStyles.tableRow}>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{item.measurementId}</Text>
                        </View>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{DateHelper.FormatDate(item.detectedDate)}</Text>
                        </View>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{SplitText(item.detectedBy, 12)}</Text>
                        </View>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{SplitText(item.building, 12)}</Text>
                        </View>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{SplitText(item.department, 12)}</Text>
                        </View>
                        <View style={PdfStyles.tableCell3}>
                            <Text style={FlexTable.rowItemText}>{SplitText(item.machine, 12)}</Text>
                        </View>
                        <View style={[PdfStyles.tableCell3, { borderRight: "none" }]}>
                            <Text style={FlexTable.rowItemText}>{item.leakageRepaired ? t("Yes") : t("No")}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}
