import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';
import { API } from '../../../helper/ApiHelper';
import { useTranslation } from 'react-i18next';
import { Document, pdf } from '@react-pdf/renderer';
import PrintLeakagesPdf from "./PrintLeakagesPdf"
import { SpinnerComponent, ButtonComponent, HeadingComponent, CheckboxComponent } from '../../Reusable/';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { asyncImage } from '../../../helper/Pdf';
import { getExecutorLogo } from '../../../helper/Pdf';
import { PrintLeakagesModel } from './PrintLeakagesModel';
import * as Mapper from '../../../helper/Mappers';

export default function PrintLeakagesDialog(props) {
    const { leakageData, ldarCalcResults, open, setOpen } = props;

    const getProjectImagesUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/project/";

    const { id, country } = useParams();
    const { t } = useTranslation();
    const [model, setModel] = useState(PrintLeakagesModel(t));
    const [isGeneratePressed, setIsGeneratePressed] = useState(false);

    const pdfDocument = (leakages, dataIMG, logo) => (
        <Document>
            {leakages.map((item, index) => <PrintLeakagesPdf
                logo={logo}
                item={item}
                dataIMG={dataIMG}
                ldarCalcResults={ldarCalcResults}
                key={index}
                index={index}
                country={country}
            />)}
        </Document>
    );

    const generatePDFDocument = async (leakages) => {

        setIsGeneratePressed(true);

        var dataIMG = await API.get(`${getProjectImagesUrl}${id}/images`);
        if (dataIMG == undefined || leakageData == undefined) {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
            setIsGeneratePressed(false);
            return;
        }

        try {
            let logo = await getExecutorLogo();
            let newImageArray = await asyncImage(dataIMG);
            const blobPdf = await pdf(pdfDocument(leakages, newImageArray, logo));
            blobPdf.updateContainer(pdfDocument(leakages, newImageArray, logo));
            let blob = await blobPdf.toBlob();
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "LEAKAGES_LIST.pdf");
            document.body.appendChild(link);
            link.click();
            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        } catch (e) {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            setIsGeneratePressed(false);
        }
    };

    const initiatePdfGeneration = () => {
        let filteredLeakages = leakageData.filter(x => (model.repairedLeakages.value === true || x.leakageRepaired !== true) &&
            (model.openLeakages.value === true || x.leakageRepaired !== false));

        if (filteredLeakages.length > 0)
            generatePDFDocument(filteredLeakages);
        else
            OpenGlobalSnackbar("danger", t('ErrorNoLeakagesFoundForTheSelection'));
    }

    return (
        <React.Fragment>
            <SpinnerComponent open={isGeneratePressed} />
            <Dialog open={open}>
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <HeadingComponent value={t("CO2Report")} size="h6" />
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1} direction="column">
                        <Grid item xs={12}>
                            <CheckboxComponent
                                model={model.repairedLeakages}
                                onChange={e => Mapper.updateModel("repairedLeakages", e.target.checked, model, setModel)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxComponent
                                model={model.openLeakages}
                                onChange={e => Mapper.updateModel("openLeakages", e.target.checked, model, setModel)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="PrintLeakagesDialog-cancel" value={t('Cancel')} color="secondary" onChange={() => setOpen(false)}></ButtonComponent></Grid>
                        <Grid item><ButtonComponent id="PrintLeakagesDialog-print" value={t('Generate')} color="primary" onChange={initiatePdfGeneration}></ButtonComponent></Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
